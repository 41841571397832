<template>
  <div>
    <index path="/datareport/sunburst"></index>
    <div style="position: absolute; right: 1%; width: 81%; top: 1%">
      <div id="main" style="width: 100%; margin-top: 7vh; height: 80vh"></div>
    </div>
  </div>
</template>

<script>
import index from "../index";
import { getSunburstChart } from "@/api/datareport";
import echarts from "echarts";

export default {
  components: {
    index,
  },
  data() {
    return {
      colorItem: {
        item1: "#ee6666", //服务商
        item2: "#91cc75", //客户
        item3: "#73c0de", //设备
      },
    };
  },
  mounted() {
    this.initChart();
  },
  methods: {
    findChildren(data) {
      let serviceChildren = [];
      if (data.children != undefined && data.children.length > 0) {
        data.children.forEach((element) => {
          let service1 = {
            name: element.name ? element.name : element.model,
          };
          if (element.children != undefined) {
            if (element.model && element.model != undefined) {
              service1.itemStyle = {
                color: this.colorItem.item3,
              };
              element.children.forEach((e) => {
                e.itemStyle = {
                  color: this.colorItem.item3,
                };
              });
              service1.children = element.children;
            } else if (
              element.children != undefined &&
              element.children.length > 0
            ) {
              service1.children = this.findChildren(element);
              service1.itemStyle = {
                color: element.pid && element.pid != undefined ? this.colorItem.item1 : this.colorItem.item2,
              };
            }
          } else {
            service1.value = 1;
            service1.itemStyle = {
              color: element.pid && element.pid != undefined ? this.colorItem.item1 : this.colorItem.item2,
            };
          }
          serviceChildren.push(service1);
        });
      }
      return serviceChildren;
    },
    findDownService(data) {
      if (data.service != undefined) {
        let children = [];
        if (
          data.service.children != undefined &&
          data.service.children.length > 0
        ) {
          data.service.children.forEach((element) => {
            let service = { name: element.name };
            service.itemStyle = {
              color: this.colorItem.item1,
            };
            if (element.children != undefined && element.children.length > 0) {
              service.children = this.findChildren(element);
            } else if (element.service != undefined && element.service) {
              //有子服务商和终端用户
              let chil = [];
              chil.push({
                name: "子服务商",
                itemStyle: {
                  color: this.colorItem.item1,
                },
                children: this.findDownService(element),
              });
              chil.push({
                name: "终端用户",
                itemStyle: {
                  color: this.colorItem.item2,
                },
                children: this.findDownOrgin(element),
              });
              service.children = chil;
            } else {
              service.value = 1;
            }
            children.push(service);
          });
        }
        return children;
      }
    },
    findDownOrgin(data) {
      if (data.orgin != undefined) {
        let children = [];
        if (
          data.orgin.children != undefined &&
          data.orgin.children.length > 0
        ) {
          data.orgin.children.forEach((element) => {
            let orginData = {};
            orginData.name = element.name;
            orginData.itemStyle = {
              color: this.colorItem.item2,
            };
            if (element.children && element.children.length > 0) {
              let orginChildren = [];
              element.children.forEach((ele) => {
                let orgin1 = {};
                orgin1.name = ele.model;
                orgin1.itemStyle = {
                  color: this.colorItem.item3,
                };
                if (ele.children.length > 0) {
                  ele.children.forEach((e) => {
                    e.itemStyle = {
                      color: this.colorItem.item3,
                    };
                  });
                  let orgin2 = ele.children;
                  orgin1.children = orgin2;
                  orginChildren.push(orgin1);
                } else {
                  orgin1.value = 1;
                }
                orginData.children = orginChildren;
              });
            } else {
              orginData.value = 1;
            }
            children.push(orginData);
          });
        }
        return children;
      }
    },

    initChart() {
      var option;
      option = {
        visualMap: {
          right: 40,
          top: 70,
          color: ["#73c0de", "#91cc75", "#ee6666"],
          categories: ["服务商", "终端用户", "设备"],
        },
        title: {
          text: "",
          textStyle: {
            fontSize: 14,
            align: "center",
          },
        },
        series: {
          type: "sunburst",
          data: chartData,
          radius: [0, "100%"],
          sort: undefined,
          // emphasis: {
          //   focus: "ancestor",
          // },
          label: {
            rotate: "tangential",
            formatter: function (v) {
              var text = v.name;
              return text.length > 4 ? text.substr(0, 5) + "..." : text;
            },
          },
        },
      };
      var chartData = [];
      getSunburstChart().then((res) => {
        let data = res.data.data;
        if (data.length == 1 && (data[0].orgin != undefined || data[0].service != undefined)) {
          let orginData = this.findDownOrgin(data[0]);
          let orgin = {
            name: "终端用户",
            itemStyle: {
              color: this.colorItem.item2,
            },
            children: orginData,
          };
          let serviceData = this.findDownService(data[0]);
          let service = {
            name: "子服务商",
            itemStyle: {
              color: this.colorItem.item1,
            },
            children: serviceData,
          };
          chartData.push(orgin)
          chartData.push(service)
          chartData = [{
            name:data[0].name,
            itemStyle:{
              color: this.colorItem.item1,
            },
            children:chartData
          }]
        } else if (data.length == 1 && data[0].children != undefined) {
          data.forEach((element) => {
            let datas = { name: element.name };
            datas.itemStyle = {
              color:
                element.pid && element.pid != undefined
                  ? this.colorItem.item1
                  : this.colorItem.item2,
            };
            if (element.children && element.children != undefined) {
              datas.children = this.findChildren(element);
            } else if (element.service && element.service != null) {
              datas.children = this.findDownService(element);
            } else {
              datas.value = 1;
            }
            chartData.push(datas);
          });
        } else {
          data.forEach((element) => {
            let datas = { name: element.name };
            datas.itemStyle = {
              color: element.pid && element.pid != undefined ? this.colorItem.item1 : this.colorItem.item2,
            };
            datas.value = 1
            chartData.push(datas);
          })
        }
        var chartDom = document.getElementById("main");
        var myChart = echarts.init(chartDom);
        option.series.data = chartData;
        option && myChart.setOption(option);
      });
    },
  },
};
</script>

<style scoped>
</style>